import * as React from 'react';
import {useEffect, useState} from 'react';
import {useKeycloak} from "@react-keycloak/web";
import {fetchAuthenticated} from "./request";
import {managerUrl} from "./util";
import {Box, Button, Snackbar, TextField} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

export function Tenant({showSnackbarWithConfiguration, setShowLoading}) {

    const {keycloak} = useKeycloak();
    const [currentTenantId, setCurrentTenantId] = useState((keycloak.tokenParsed as any)?.tenantId);
    const [fetchTenants, setFetchTenants] = useState(true);
    const [tenants, setTenants] = useState([]);
    const [newTenantName, setNewTenantName] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (fetchTenants) {
            setFetchTenants(false);
            setShowLoading(true);
            fetchAuthenticated(keycloak, `${managerUrl}/manager-api/tenants`)
                .then(response => response.ok ? response.json() : Promise.reject(response))
                .then(data => {
                    let exists = false;
                    for (const datum of data) {
                        if (datum.id === currentTenantId) {
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        data.push({id: currentTenantId, name: currentTenantId});
                    }
                    setTenants(data);
                })
                .finally(() => {
                    setShowLoading(false);
                });
        }
    }, [currentTenantId, setTenants, fetchTenants, keycloak]);

    const changeTenant = (newTenantId) => {
        setShowLoading(true);
        const blob = new Blob([JSON.stringify({tenantId: newTenantId})], {type: 'application/json'});
        fetchAuthenticated(keycloak, `${managerUrl}/manager-api/set-current-tenant`, {
            method: 'PUT',
            body: blob
        })
            .then((response) => {
                if (response.status === 200) {
                    setCurrentTenantId(newTenantId);
                    showSnackbarWithConfiguration({
                        severity: 'success',
                        message: `Successfully switched to demo environment '${newTenantId}'. Please refresh your Flowable applications.`
                    });
                } else {
                    console.log(`Failed to set tenant to "${newTenantId}"`, response);
                    showSnackbarWithConfiguration({
                        severity: 'error',
                        message: `Failed to switch to demo environment '${newTenantId}'. Please try again later.`
                    });
                }
            })
            .finally(() => {
                setShowLoading(false);
            });
    }

    const createTenant = (name) => {
        const blob = new Blob([JSON.stringify({name})], {type: 'application/json'});
        setShowLoading(true);
        fetchAuthenticated(keycloak, `${managerUrl}/manager-api/tenants`, {
            method: 'POST',
            body: blob
        })
            .then((response) => {
                if (response.status === 200) {
                    setFetchTenants(true);
                    setNewTenantName("");
                    showSnackbarWithConfiguration({
                        severity: 'success',
                        message: `Successfully created demo environment '${name}'. You still need to select the demo environment above.`
                    });
                } else {
                    console.log(`Failed to create tenant "${name}"`, response);
                    showSnackbarWithConfiguration({
                        severity: 'error',
                        message: `Failed to create demo environment "${name}". Most likely a demo environment with a similar name already exists.`
                    });
                }
            })
            .finally(() => {
                setShowLoading(false);
            });
    };

    const filteredTenants = tenants?.filter(tenant => 
        tenant.id?.toLowerCase().includes(searchTerm.toLowerCase()) || 
        tenant.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return <>
        <h1>Demo Environments</h1>
        <p>
            This site can be used to adjust the demo environment of your account.
            Which is especially useful when you work with somebody together on a project.
            In this case, please create a new demo environment for the specific project.
            The demo environment will be automatically pre-populated with users, you still need to add apps.
        </p>
        <p>Current Environment: <strong>{currentTenantId}</strong></p>

        <h2>Available Demo Environments</h2>
        <TextField
            variant="outlined"
            label="Search Demo Environments"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            margin="normal"
            style={{ width: '300px' }}
        />
        <p>
            The <strong>bold</strong> demo environment is your current demo environment.
            Click on a demo environment to switch to the specific demo environment.
        </p>
        <ul>
            {filteredTenants.map((tenant) => {
                const styles: React.CSSProperties = {};
                if (tenant.id === currentTenantId) {
                    styles.fontWeight = "bold";
                }
                return <li key={tenant.id} style={styles}><a style={{cursor: "pointer"}}
                                                             onClick={() => changeTenant(tenant.id)}>{tenant.name}</a>
                </li>;
            })}
        </ul>

        <h2>Create New Demo Environment</h2>
        <Box
            component="form"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
        >
            <TextField variant="outlined" label="Demo Environment Name" value={newTenantName}
                       fullWidth={true}
                       margin="normal"
                       style={{ width: '300px' }}
                       onChange={(e) => setNewTenantName(e.target.value as string)}/>
            <Button variant="outlined" onClick={() => createTenant(newTenantName)}>Create Demo Environment</Button>
        </Box>
    </>;
}