import {useEffect, useState} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {fetchAuthenticated} from "./request";

export function useFlowableApi(url: string, dependencies: any[] = [], setShowLoading = null) {
    const [result, setResult] = useState(null);
    const [reload, setReload] = useState(0);
    const {keycloak} = useKeycloak();

    useEffect(() => {
        if (!result && url) {
            if (setShowLoading) {
                setShowLoading(true);
            }
            fetchAuthenticated(keycloak, url)
                .then(response => response.ok ? response.json() : Promise.reject(response))
                .then(data => setResult(data))
                .finally(() => {
                    if (setShowLoading) {
                        setShowLoading(false);
                    }
                });
        }
        // eslint-disable-next-line
    }, [reload, ...dependencies]);
    return [ result, () => {
        setResult(null);
        setReload(reload + 1);
    } ];
}


