import * as React from "react";
import {useParams} from "react-router-dom";
import {useFlowableApi} from "./hooks";
import * as MarkdownIt from "markdown-it";
import {deployAppToFlowable} from "./util";
import {useKeycloak} from "@react-keycloak/web";
import {Button} from "@material-ui/core";

export function AppsDetails({showSnackbarWithConfiguration, setShowLoading}) {


    const {appKey} = useParams<{ appKey: string }>();
    const {keycloak} = useKeycloak();
    const currentTenantId = (keycloak.tokenParsed as any)?.tenantId;
    const [app] = useFlowableApi(`/manager-api/apps/${appKey}?tenantId=${currentTenantId}`, []);

    const deployApp = (appKey: string) => deployAppToFlowable(appKey, keycloak, setShowLoading, showSnackbarWithConfiguration);

    const md = new MarkdownIt();

    return app ? <>
            <Button variant="contained" style={{marginTop: "15px", float: "right"}}
                    onClick={() => deployApp(app.key)}>Add/Update</Button>
            <h1>{app.name}</h1>
            <div className="markdown-content" dangerouslySetInnerHTML={{__html: md.render(app.instructions)}}/>
        </> :
        <>App couldn't be found</>;
}