import {KeycloakInstance} from "keycloak-js";
import {fetchAuthenticated} from "./request";

export function computeUrl(prefix: string, fallbackUrl: string) {
    const hostname = window.location.host;

    let url = fallbackUrl;
    if (hostname.indexOf("localhost") === -1) {
        const firstDot = hostname.indexOf(".") + 1;
        url = `${prefix}${hostname.substring(firstDot)}`;
    }

    return `${window.location.protocol}//${url}`;
}

export const deployAppToFlowable = (
    appKey: string,
    keycloak: KeycloakInstance,
    setShowLoading: (v: boolean) => void,
    showSnackbarWithConfiguration: ({}: { severity: string, message: string }) => void
) => {
    const tenantId = (keycloak.tokenParsed as any)?.tenantId;
    const blob = new Blob([JSON.stringify({key: appKey, tenantId})], {type: 'application/json'});
    setShowLoading(true);
    fetchAuthenticated(keycloak, `${managerUrl}/manager-api/apps/add-to-design`, {
        method: 'POST',
        body: blob
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                console.log(`Failed to add app with "${appKey}" to Flowable Design`, response);
                showSnackbarWithConfiguration({
                    severity: 'error',
                    message: `Failed to add app with "${appKey}" to Flowable Design`
                });
                return Promise.reject();
            }
        })
        .then(body => {
            showSnackbarWithConfiguration({
                severity: 'success',
                message: `The app "${appKey}" has been added successfully to your demo environment.`
            });
        })
        .finally(() => {
            setShowLoading(false);
        });
};

export const managerUrl = `${computeUrl('manage.', 'localhost:3001')}`;
