import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Container, makeStyles, Snackbar} from "@material-ui/core";
import {Tenant} from "./Tenant";
import {Apps} from "./Apps";
import {Navigation} from "./Navigation";
import {AppsDetails} from "./AppsDetails";
import {Alert} from "@material-ui/lab";
import {Backdrop, CircularProgress} from "@mui/material";
import * as React from "react";
import {useState} from "react";

function App() {

    const [snackbarConfiguration, showSnackbarWithConfiguration] = useState(null);
    const [showLoading, setShowLoading] = useState(false);

    const useStyles = makeStyles({
        background: {
            position: 'fixed',
            left: 0,
            top: 0,
            backgroundColor: null,
            width: '100%',
            height: '100%',
            zIndex: -100
        },
        container: {
            backgroundColor: null,
            color: null,
            paddingTop: 15,
            paddingBottom: 15,
            marginTop: 10,
            marginBottom: 10,
            boxShadow: '0 8px 17px 2px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%)',
            minHeight: 'calc(100vh - 30px)'
        },
        logo: {
            textAlign: 'right'
        }
    });
    const classes = useStyles();

    const props = {showSnackbarWithConfiguration, setShowLoading, showLoading};

    return <>
        <div className={classes.background}></div>
        <Container className={classes.container}>
            <Router>
                <div>
                    <Snackbar open={snackbarConfiguration != null} autoHideDuration={6000}
                              onClose={() => showSnackbarWithConfiguration(null)}>
                        {snackbarConfiguration &&
                        <Alert onClose={() => showSnackbarWithConfiguration(null)}
                               severity={snackbarConfiguration.severity}>
                            {snackbarConfiguration.message}
                        </Alert>}
                    </Snackbar>
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={showLoading}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <Navigation/>
                    <Switch>
                        <Route path="/tenants">
                            <Tenant {...props} />
                        </Route>
                        <Route path="/apps/:appKey">
                            <AppsDetails {...props} />
                        </Route>
                        <Route path="/">
                            <Apps {...props} />
                        </Route>
                    </Switch>
                </div>
            </Router>
        </Container>
    </>;
}

export default App;
