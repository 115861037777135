import {fetchAuthenticated} from "./request";
import {deployAppToFlowable, managerUrl} from "./util";
import {useKeycloak} from "@react-keycloak/web";
import {useFlowableApi} from "./hooks";
import {Button, Card, CardActions, CardContent, makeStyles, Typography, Chip, Box, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import CachedIcon from '@mui/icons-material/Cached';
import * as React from "react";
import {useState, useMemo} from "react";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    root: {
        width: 275,
        margin: 5
    },
    title: {
        fontSize: 14,
    },
    tagContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 4,
        marginTop: 8,
    },
    tag: {
        fontSize: 12,
    },
});

export function Apps({showSnackbarWithConfiguration, setShowLoading, showLoading}) {

    const {keycloak} = useKeycloak();
    const [apps, reloadApps] = useFlowableApi('/manager-api/apps', [], setShowLoading);
    const currentTenantId = (keycloak.tokenParsed as any)?.tenantId;

    const classes = useStyles();

    const reload = () => {
        setShowLoading(true);
        fetchAuthenticated(keycloak, `${managerUrl}/manager-api/apps/fetch`, {
            method: 'PUT',
        })
            .then((response) => {
                if (response.status === 200) {
                    reloadApps();
                } else {
                    console.log('Response failed');
                }
            })
            .finally(() => {
                setShowLoading(false);
            });
    }

    const deployApp = (appKey: string) => deployAppToFlowable(appKey, keycloak, setShowLoading, showSnackbarWithConfiguration);

    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [selectedComplexity, setSelectedComplexity] = useState('');
    const [customCodeUsed, setCustomCodeUsed] = useState('');
    const [showLibraries, setShowLibraries] = useState(false);
    const [searchText, setSearchText] = useState('');

    const filteredApps = useMemo(() => {
        return apps?.filter(app => 
            (!selectedIndustry || app.tags?.includes(`Industry: ${selectedIndustry}`)) &&
            (!selectedComplexity || app.tags?.includes(`Complexity: ${selectedComplexity}`)) &&
            (!customCodeUsed || app.tags?.includes(`Custom Code: ${customCodeUsed}`)) &&
            (showLibraries || !app.tags?.includes('Library')) &&
            (searchText === '' || 
                app.name.toLowerCase().includes(searchText.toLowerCase()) ||
                app.description.toLowerCase().includes(searchText.toLowerCase()))
        ) || [];
    }, [apps, selectedIndustry, selectedComplexity, customCodeUsed, showLibraries, searchText]);

    const industries = useMemo(() => {
        return Array.from(new Set(
            apps?.flatMap(app => 
                app.tags?.filter(tag => tag.startsWith('Industry: '))
                    .map(tag => tag.slice('Industry: '.length))
                || []
            ) || []
        ));
    }, [apps]);

    const complexities = useMemo(() => {
        return Array.from(new Set(
            apps?.flatMap(app => 
                app.tags?.filter(tag => tag.startsWith('Complexity: '))
                    .map(tag => tag.slice('Complexity: '.length))
                || []
            ) || []
        ));
    }, [apps]);

    function TagChips({ tags }) {
        const classes = useStyles();
        return (
            <Box className={classes.tagContainer}>
                {tags.map((tag, index) => (
                    <Chip key={index} label={tag} size="small" className={classes.tag} />
                ))}
            </Box>
        ); 
    }

    return apps ? <>
            <Button variant="contained" style={{marginTop: "15px", float: "right"}} onClick={() => reload()}
                    startIcon={<CachedIcon/>}>Reload</Button>
            <h1>Flowable Design Apps</h1>
            <p>In this screen you can add apps to your current demo environment <strong>{currentTenantId}</strong>.<br/>
                Those apps will be added to Flowable Design and Work.</p>
            
            <FormControl style={{minWidth: 200, marginBottom: 20, marginRight: 20}}>
                <InputLabel id="industry-select-label">Filter by Industry</InputLabel>
                <Select
                    labelId="industry-select-label"
                    value={selectedIndustry}
                    onChange={(e) => setSelectedIndustry(e.target.value as string)}
                >
                    <MenuItem value="">All</MenuItem>
                    {industries.map((industry) => (
                        <MenuItem key={industry.toString()} value={industry.toString()}>{industry}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl style={{minWidth: 200, marginBottom: 20, marginRight: 20}}>
                <InputLabel id="complexity-select-label">Filter by Complexity</InputLabel>
                <Select
                    labelId="complexity-select-label"
                    value={selectedComplexity}
                    onChange={(e) => setSelectedComplexity(e.target.value as string)}
                >
                    <MenuItem value="">All</MenuItem>
                    {complexities.map((complexity) => (
                        <MenuItem key={complexity.toString()} value={complexity.toString()}>{complexity}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl style={{minWidth: 200, marginBottom: 20}}>
                <InputLabel id="customCode-select-label">Filter by Custom Code</InputLabel>
                <Select
                    labelId="customCode-select-label"
                    value={customCodeUsed}
                    onChange={(e) => setCustomCodeUsed(e.target.value as string)}
                >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </Select>
            </FormControl>

            <Box display="flex" alignItems="center" marginBottom={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showLibraries}
                            onChange={(e) => setShowLibraries(e.target.checked)}
                            color="primary"
                        />
                    }
                    label="Show Libraries"
                />
                <TextField
                    label="Search apps"
                    variant="outlined"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{marginLeft: 20, width: 200}}
                />
            </Box>

            <div className={classes.container}>
                {filteredApps.map(app => {
                    return <Card className={classes.root} key={app.key}>
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {app.name}
                            </Typography>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                {app.description}
                            </Typography>
                            {app.tags && app.tags.length > 0 && <TagChips tags={app.tags} />}
                        </CardContent>
                        <CardActions>
                            {app.hasInstructions &&
                            <Button size="small" color="primary" href={`/apps/${app.key}`}>Details</Button>}
                            <Button size="small" color="primary" onClick={() => deployApp(app.key)}>Add/Update</Button>
                        </CardActions>
                    </Card>;
                })}
            </div>
        </> :
        <>{!showLoading ? "There are no apps available for installation" : ""}</>;
}